import '../../styles/pages/spring-summer-2024/changing-the-face-of-science.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2024-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';

import Hero from '../../images/spring-summer-2024/changing-the-face-of-science/changing-the-face-of-science-hero-1920x1080.jpg';
import socialBanner from "../../images/spring-summer-2024/social-banners/changing-the-face-of-science-social-banners-1200x630.jpg";
import titelGraphic from "../../images/spring-summer-2024/changing-the-face-of-science/changing-the-face-of-science-title-380x245.svg";
import ctaBanner from '../../images/spring-summer-2024/changing-the-face-of-science/changing-the-face-of-science-cta-banner-1920x515.jpg';

import changingthefaceofscienceIMG1 from '../../images/spring-summer-2024/changing-the-face-of-science/changing-the-face-of-science-image-1-800x533.jpg';
import changingthefaceofscienceIMG2 from '../../images/spring-summer-2024/changing-the-face-of-science/changing-the-face-of-science-image-1-533x800.jpg';
import changingthefaceofscienceIMG3 from '../../images/spring-summer-2024/changing-the-face-of-science/changing-the-face-of-science-image-2-533x800.jpg';
import changingthefaceofscienceIMG4 from '../../images/spring-summer-2024/changing-the-face-of-science/changing-the-face-of-science-image-2-800x533.jpg';
import changingthefaceofscienceIMG5 from '../../images/spring-summer-2024/changing-the-face-of-science/changing-the-face-of-science-image-3-800x533.jpg';



export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Diverse Scientists Explore Preshistoric Past as ‘Eons’ Hosts';
    var pageDesc = 'Cal State Fullerton geology alumni shatter science stereotypes as co-hosts of the PBS show “Eons” and take viewers on a journey through the prehistoric past.';
    var slug = 'changing-the-face-of-science';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [

      {
        src: changingthefaceofscienceIMG3,
        width: 533,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: changingthefaceofscienceIMG4,
        width: 800,
        height: 533,
        alt: '',
        title: ''
      },


    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2024">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2024 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s"><img className='titelGraphic shadow' src={titelGraphic} alt='Four Titans One Legacy' /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">Geology alumni with diverse identities co-host the PBS show ‘Eons’ and take viewers on a journey through the prehistoric past.
</p>
            <span className="animated fadeInDown delay-1s byline">By Debra Cano Ramos / Photos Courtesy of Gabriel-Philip Santos and Michelle Barboza-Ramirez</span>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>


          <p><span className="intro-text">Geology alumni</span> Michelle Barboza-Ramirez and Gabriel-Philip Santos dig paleontology &mdash; and their fascination with fossils turned into a passion for science storytelling.</p>
<p>Santos has always been interested in science and admired naturalist David Attenborough and the late crocodile hunter Steve Irwin.</p>
<p>&ldquo;Both had the amazing ability to tell stories about nature and inspire people around the world,&rdquo; Santos says. &ldquo;Growing up, a part of me wanted to be like them.&rdquo;</p>
<p>Barboza-Ramirez adds: &ldquo;What got me excited and made me passionate about science was talking to people about fossils and making science accessible and relatable.&rdquo;</p>
<p>The former Cal State Fullerton student researchers are co-hosts of the PBS show &ldquo;Eons.&rdquo; The self-described nerdy scientists and fossil fanatics take viewers on a journey through the history of life on Earth &mdash; from about 2 billion years ago to the most recent Ice Age.</p>
<p>&ldquo;<a href="https://www.pbs.org/show/eons/" title="Eons PBS" target="_blank">Eons</a>&rdquo; YouTube and TikTok episodes highlight science topics with fun, friendly, short and easy-to-understand shows written by scientists for nonscientists.</p>
<figure className='align-wide'>
            <img src={changingthefaceofscienceIMG1} alt="" />
  <figcaption>Michelle Barboza-Ramirez and Gabriel-Philip Santos at the Burke Museum in Seattle</figcaption>
</figure>
<p>&ldquo;The show brings cutting-edge science to your screen,&rdquo; says Barboza-Ramirez &rsquo;16 (B.S. geology), who started filming episodes in 2021.</p>
<p>Barboza-Ramirez and Santos are set to begin taping 2024 season episodes this summer at the &ldquo;Eons&rdquo; studio in Missoula, Montana. A production of Complexly for PBS Digital Studios, &ldquo;Eons&rdquo; has 2.8 million subscribers and more than 635 million views on YouTube. The show boasts 365K followers on <a href="https://www.tiktok.com/@pbseons?lang=en" title="TikTok PBS" target="_blank">TikTok</a> and 106K followers on <a href="https://www.instagram.com/eonsshow/" title="Instagram PBS" target="_blank">Instagram</a>.</p>
<h2>TELLING TRUE TALES OF A PREHISTORIC WORLD</h2>
<p>&ldquo;Is life on Mars related to life on Earth?&rdquo; &ldquo;What led to the massive collision of tectonic plates millions of years ago to form the San Andreas Fault?&rdquo; These are some of the perplexing questions about Earth&rsquo;s prehistoric past Barboza-Ramirez poses to &ldquo;Eons&rdquo; viewers in edgy, factual and fascinating episodes.</p>
<p>Barboza-Ramirez&rsquo;s favorite episodes include &ldquo;How a Mass Extinction Changed Our Brains,&rdquo; &ldquo;A Natural History of Mars&rdquo; and &ldquo;<a href="https://www.youtube.com/watch?v=xZ4twDqSNaY&t=230s" title="How Plate Tectonics Transformed Los Angeles" target="_blank">How Plate Tectonics Transformed Los Angeles</a>.&rdquo; Filmed in and around Los Angeles, the show explores the region&rsquo;s natural history dating back 30 million years ago.</p>
<p>Santos &rsquo;18 (M.S. geology) started his co-hosting stint by creating &ldquo;Eons&rdquo; TikTok short videos last fall. He is thrilled to be part of the &ldquo;Eons&rdquo; cast and tell stories about deep time, paleontology and natural history.</p>
<p>In one video, Santos explains fossil turduckens &mdash; ancient fossil food chains that show an animal within an animal &mdash; like a 48-million-year-old snake that ate an ancient lizard who last ate a tiny insect. In another clip, he ponders burning questions about fossil chile pepper plants &mdash; even sacrificing his taste buds by biting into a super hot Thai bird&rsquo;s eye chile pepper.</p>
<p>&ldquo;I get to work with some of the best science storytellers and learn from them,&rdquo; says Santos, director of visitor engagement and education at the Raymond M. Alf Museum of Paleontology at The Webb Schools in Claremont.</p>

<h2>SHATTERING STEREOTYPES IN SCIENCE</h2>
<p>As science communicators, both share how their diverse identities are assets in helping others understand the wonders of the natural world. Barboza-Ramirez, Latinx, queer and nonbinary, uses they/them pronouns, and Santos, Filipino American, strive to be inclusive examples for the younger generation.</p>
<figure className='align-left large align-outside'>
            <img src={changingthefaceofscienceIMG2} alt="" />
  <figcaption>Michelle Barboza-Ramirez at an excavation site of ancient gomphotheres in 2017</figcaption>
</figure>
<p>&ldquo;We not only need more women in science but queer scientists, scientists of color, scientists with disabilities &mdash; diversity is lacking in all areas,&rdquo; says Barboza-Ramirez, who is involved with outreach programs to encourage geoscience diversity.</p>
<p>&ldquo;Science is not as objective as it seems; when there is a lack of viewpoints, there is a lack of innovation, creativity and concern for those who are not represented.&rdquo;</p>
<p>Barboza-Ramirez earned a master&rsquo;s degree in vertebrate paleontology from the University of Florida and is a geology instructor at two community colleges in Seattle. They also direct a National Science Foundation-funded STEM program for underrepresented students.</p>
<p>In his new side gig, Santos also wants to disrupt stereotypes in science: &ldquo;I want to show students that this brown-skinned Filipino American guy is a paleontologist and science educator on a large platform.</p>
<p>&ldquo;Because there are so few Filipino scientists represented in science media, I have this incredible opportunity to be part of that representation and inspire Filipino students to pursue science careers.&rdquo;</p>
<h2>DIGGING PALEONTOLOGY RESEARCH</h2>
<p>The paleontologists turned science educators studied Orange County fossils &mdash; animals that lived millions of years ago in the region &mdash; under the mentorship of James F. Parham, professor of geological sciences. Barboza-Ramirez studied <a href="https://news.fullerton.edu/2017/01/crocodile-study/" title="Ancient crocodiles" target="_blank">ancient crocodiles</a> and Santos researched <a href="https://news.fullerton.edu/2016/01/fossil-student-study/" title="Desmostylians" target="_blank">desmostylians</a>, an extinct hippo-like marine mammal, and a 45-million-year-old bonebed from South Orange County.</p>
<p>To foster an appreciation for science and remove barriers, the friends co-founded Cosplay for Science, which combines storytelling, pop culture and role-playing as fictional characters to teach science.</p>
<ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure className="align-wide gallery">
              <Gallery photos={photos}  />
              <figcaption>
                <strong>Left:</strong> Michelle Barboza-Ramirez and Gabriel-Philip Santos at 
CSUF Commencement 2016 
                <strong>&nbsp;Right:</strong> Gabriel-Philip Santos with a puppet of “Dinosaur Joe” (Courtesy of Raymond M. Alf Museum of Paleontology) 
              </figcaption>
            </figure>
          </ScrollAnimation>
<p>While his parents encouraged him to pursue a medical career, as a graduate student, Santos rediscovered his fascination with learning more about ancient life on Earth.</p>
<p>&ldquo;I&rsquo;ve learned from so many to develop my own style that incorporates my enjoyment of storytelling, background as a scientist &mdash; and love of helping others understand science.&rdquo;</p>
<figure className='align-wide'>
            <img src={changingthefaceofscienceIMG5} alt="" />
  <figcaption>Cosplay for Science founders Gabriel-Philip Santos and Michelle Barboza-Ramirez with paleontologist Billie Guerrero at Comic Con Revolution (Courtesy of Cosplay for Science)</figcaption>
</figure>
<p>Barboza-Ramirez entered college as a business administration major and never imagined becoming a paleontologist, a science storyteller or traveling the world to visit dig sites.</p>
<p>&ldquo;Taking an introductory geology class sparked my curiosity. Once I figured out that you could get paid to go hiking, play in the dirt, dig up fossils and tell amazing science stories, there was no going back.&rdquo; <span className="end-mark" /></p>


</div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the Department of Geological Sciences</span><br />
              <a href="https://give.fullerton.edu/geology" target='_blank' rel='noreferrer'>give.fullerton.edu/geology</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 